<template>
    <div class="basic_data_edit">
        <div class="data_content scrollbar">
            <!-- 按钮 -->
            <div class="botton_box">
                <div class="button_list">
                    <sn-button
                        :snButton="snButton"
                        @handleChange="handleChange"
                    ></sn-button>
                </div>
            </div>
            <!-- 表单 -->
            <el-form
                ref="formdata"
                :inline="true"
                :model="formdata.saveJson"
                :rules="rules"
                class="demo-form-inline form_row"
                size="small"
                label-width="200px"
            >
                <el-form-item label="是否启用" prop="">
                    <div style="height:32px;" class="flex-y-center">
                        <div
                            v-for="(item, index) in onOffList"
                            :key="index"
                            class="flex-between-y-center layerItem"
                        >
                            <onOff
                                :onOffState="item.state"
                                :item="item"
                                :index="index"
                                @switchStateChange="switchStateChange"
                            ></onOff>
                            <div class="name">{{ item.name }}</div>
                        </div>
                    </div>
                </el-form-item>
                <el-form-item label="" prop=""> </el-form-item>
                <el-form-item label="设备名称" prop="SBMC">
                    <el-input
                        v-model="formdata.saveJson.SBMC"
                        placeholder="请输入设备名称"
                        :maxlength="50"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备类型" prop="LX">
                    <el-select
                        v-model="formdata.saveJson.LX"
                        placeholder="请选择设备类型"
                        :disabled="true"
                    >
                        <el-option
                            v-for="(item, index) in equipData"
                            :key="index"
                            :label="item.MC"
                            :value="item.ID"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="遗产要素" prop="YCYSBM">
                    <!-- <el-select
                        v-model="formdata.saveJson.YCYSBM"
                        placeholder="请选择遗产要素"
                        :disabled="!isEdit"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in ycysList"
                            :key="index"
                            :label="item.MC"
                            :value="item.BM"
                        ></el-option>
                    </el-select> -->
                    <el-cascader
                        class="ycys-cascader"
                        v-model="ycysValue"
                        :options="ycysList"
                        :props="ycysProps"
                        clearable
                        filterable
                        @change="handleYcycChanged">
                    </el-cascader>
                </el-form-item>
                <el-form-item label="设备编码">
                    <el-input
                        v-model="formdata.saveJson.SBBH"
                        placeholder="请输入设备编码"
                        :maxlength="50"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="采集周期" prop="ZQ">
                    <el-input
                        v-model.number="formdata.saveJson.ZQ"
                        :maxlength="50"
                        placeholder="请输入采集周期"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="周期单位" prop="ZQDW">
                    <el-select
                        v-model="formdata.saveJson.ZQDW"
                        placeholder="请选择周期单位"
                        :disabled="!isEdit"
                    >
                        <el-option
                            v-for="(item, index) in zqdwList"
                            :key="item.COLUMNID + index"
                            :label="item.NAME"
                            :value="item.CODE"
                        ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="安装时间" prop="AZSJ">
                    <!--   type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss" -->
                    <el-date-picker
                        v-model="formdata.saveJson.AZSJ"
                        placeholder="请选择安装时间"
                        value-format="yyyy-MM-dd"
                        :disabled="!isEdit"
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="安装位置">
                    <el-input
                        v-model="formdata.saveJson.AZDWZMS"
                        placeholder="请输入安装位置"
                        :maxlength="50"
                        :disabled="!isEdit"
                    ></el-input>
                </el-form-item>
                <el-form-item label="安装位置坐标：">
                    <div style="display: flex; flex-flow: row nowrap">
                        <el-input
                            v-model="jwdValue"
                            :disabled="!isEdit"
                            @change="handleJwdValueChanged"
                            placeholder="示例：116.184776,42.359117"
                        ></el-input>
                        <el-button
                            style="marginLeft: 10px"
                            @click="
                                handleMapPointBtnClick(jwdValue, 'sb', null)
                            "
                            >地图选点</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item label="监测指标">
                    <el-select
                        v-model="jczbValue"
                        multiple
                        filterable
                        allow-create
                        default-first-option
                        placeholder="请选择监测指标"
                    >
                        <el-option
                            v-for="(item, index) in jczbList"
                            :key="index"
                            :label="item.Mc"
                            :value="item.Id"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="设备状态" v-show="!isAdd">
                    <el-input
                        v-model="formdata.saveJson.SBZT"
                        placeholder="暂无设备状态"
                        :maxlength="50"
                        readonly
                    ></el-input>
                </el-form-item>
                <el-form-item label="上次抓取时间" v-show="!isAdd">
                    <el-date-picker
                        v-model="formdata.saveJson.SCZQSJ"
                        placeholder="暂无上次抓取时间"
                        type="datetime"
                        value-format="yyyy-MM-dd HH:mm:ss"
                        readonly
                    ></el-date-picker>
                </el-form-item>
                <el-form-item label="设备图片" style="width:100%">
                    <basic-upload
                        ref="uploadFile"
                        :metaData.sync="metaJson"
                        savePath="equipment"
                        basicType="picture"
                    />
                </el-form-item>
                <el-form-item
                    style="width:100%"
                    label="已有图片"
                    v-if="
                        formdata.saveJson.FILE &&
                            formdata.saveJson.FILE.length > 0
                    "
                >
                    <div class="flex flex-wrap">
                        <div
                            v-for="ele in formdata.saveJson.FILE"
                            :key="ele.Id"
                            class="img-item"
                        >
                            <el-image
                                v-viewer
                                :src="ele.FILE"
                                fit="cover"
                                style="width:250px;height:240px"
                            ></el-image>
                            <div class="delete-cover">
                                <el-button
                                    type="info"
                                    icon="el-icon-delete"
                                    circle
                                    @click="handleDeletePic(ele)"
                                ></el-button>
                            </div>
                        </div>
                    </div>
                </el-form-item>
            </el-form>
            <sn-dialog :dialogData.sync="dialogDataMapPoint">
                <template>
                    <MapPoint
                        :dataSource="currentData"
                        @handleMapPointClick="handleMapPointClick"
                    ></MapPoint>
                </template>
            </sn-dialog>
        </div>
    </div>
</template>

<script>
import OnOff from "@views/big_screen/comp/OnOff.vue"; //开关
import snButton from "../edit-button.js";
import infoMixin from "../info_mixin.js";
import { mapActions } from "vuex";
import MapPoint from "./map_point.vue";
import BasicUpload from "@comp/basic_upload";
// eslint-disable-next-line no-useless-escape
const longrg = /^(\-|\+)?(((\d|[1-9]\d|1[0-7]\d|0{1,3})\.\d{0,6})|(\d|[1-9]\d|1[0-7]\d|0{1,3})|180\.0{0,6}|180)$/; // 经度正则验证
// eslint-disable-next-line no-useless-escape
const latreg = /^(\-|\+)?([0-8]?\d{1}\.\d{0,6}|90\.0{0,6}|[0-8]?\d{1}|90)$/; // 纬度正则验证
export default {
    name: "equipconfig_form",
    mixins: [infoMixin],
    data() {
        return {
            onOffList: [
                {
                    name: "",
                    state: true,
                },
            ],
            metaJson: [],
            notNeedFile: true,
            snButton: snButton,
            // 根据承诺事项来源类型不同掉服务获取承诺事项来源列表
            cnwcsj: false,
            cnwcsj_date: "",
            formdata: {
                saveJson: {
                    SFQY: 1,
                    SBMC: "",
                    LX: "",
                    YCYSBM: "",
                    AZDWZMS: "",
                    AZDJD: null,
                    AZDWD: null,
                    SBBH: "",
                    AZSJ: "",
                    ZQ: "",
                    ZQDW: "",
                    SBZT: "",
                    SCZQSJ: "",
                    JCZB: "", //监测指标
                    FILE: [],
                },
                heritageId: "",
                itemID: "116",
                userName: "",
                userId: "",
            },
            equipData: [],
            ycysValue: [],
            ycysList: [],
            ycysProps: {
                checkStrictly: true,
                value: 'BM',
                label: 'MC',
                children: 'CHILDREN'
            },
            zqdwList: [],
            sbLx: null,
            itemID: "116",

            //地图选点相关
            jwdValue: "",

            rules: {
                SBMC: [
                    {
                        required: true,
                        message: "请输入设备名称",
                        trigger: "blur",
                    },
                ],
                ZQ: [
                    {
                        required: true,
                        message: "请输入采集周期",
                        trigger: "blur",
                    },
                    { type: "number", message: "采集周期必须为数字值" },
                ],
                ZQDW: [
                    {
                        required: true,
                        message: "请选择周期单位",
                        trigger: "change",
                    },
                ],
                AZSJ: [
                    {
                        required: true,
                        message: "请选择安装时间",
                        trigger: "blur",
                    },
                ],
            },
            jczbValue: [], //监测指标绑定值
            jczbList: [], //监测指标选择框数据源
            jczbNewArr: [], //监测指标创建的新条目
            dialogDataMapPoint: {
                title: "地图选点",
                dialog: false,
                width: "",
                class: "deepBlueDialog",
                action: false,
            },
            currentData: {
                pointValue: "",
                type: "",
                index: null,
            },
        };
    },
    components: {
        MapPoint,
        BasicUpload,
        OnOff,
    },
    computed: {
        showFiles() {
            const list = this.formdata.saveJson.FILE;
            const res = [];
            if (Array.isArray(list)) {
                list.forEach((ele) => {
                    if (ele && typeof ele === "object") {
                        res.push(ele.FILE);
                    }
                });
            }
            return res;
        },
    },
    watch: {
        "formdata.saveJson.cnsxlylx": function() {},
    },
    mounted() {
        console.log(1111111, this.$route);
        this.sbLx = this.$route.query.sbLx;
        if (!this.formdata.saveJson.LX) {
            this.formdata.saveJson.LX = this.sbLx;
        }
        this.getEquipList();
        if(this.$route.query.type==='0'){
            this.getYcysList();
        }
        this.getZqdwList();
        this.getJczbList();
    },
    methods: {
        ...mapActions([
            "GetAllEquipmentList", // 获取设备类型列表
            "getYcysdtList", // 获取遗产要素列表
            "GetDetailsEquipmentData",
            "GetZBXList", //获取监测指标列表
            "SaveZBX", //保存服务(监测指标项)
            "GetZqdwList", //获取周期单位列表
            "SaveEquipmentData", //保存服务
            "deleteFileInfo", //删除照片 服务
            "GetYczcAndYcys", // 获取遗产组成和遗产要素清单（二级结构）
        ]),
        // 更改图层
        switchStateChange(state, item, index) {
            this.formdata.saveJson.SFQY = state ? 1 : 0;
        },
        async handleDeletePic(item) {
            const ids = item && item.Id ? JSON.stringify([item.Id]) : "";
            try {
                const temp = await this.$confirm(
                    "是否永久删除该图片?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                );
                if (temp === "confirm") {
                    const res = await this.deleteFileInfo({ ids });
                    if (res.ISSUCCESS) {
                        this.formdata.saveJson.FILE = this.formdata.saveJson.FILE.filter(
                            (ele) => ele !== item
                        );
                        this.$message({
                            type: "success",
                            message: "删除成功",
                        });
                        return;
                    } else {
                        this.$message({
                            type: "error",
                            message: "删除失败",
                        });
                        return;
                    }
                }
            } catch (error) {
                this.$message({
                    type: "info",
                    message: "已取消提交",
                });
            }

            // const ids = JSON.stringify([item.Id]);
            try {
                console.log(res);
            } catch (error) {
                console.log(error);
            }
        },
        // 获取设备类型列表
        async getEquipList() {
            this.equipData = [];
            let result = await this.GetAllEquipmentList();
            if (result && result.length > 0) {
                this.equipData = result;
            }
        },
        handleYcysValue() {
            if((this.formdata.saveJson.YCYSBM || this.formdata.saveJson.YCYSFL2) && this.ycysList && this.ycysList.length > 0 && (!this.ycysValue || this.ycysValue.length === 0)){
                this.ycysList.forEach((item1, index1) => {
                    // if(item1.BM === this.formdata.saveJson.YCYSBM) {
                    //     this.ycysValue.push(item1.BM);
                    // } else {
                    //     if(item1.CHILDREN && item1.CHILDREN.length > 0) {
                    //         item1.CHILDREN.forEach((item2,index2)=>{
                    //             if(item2.BM === this.formdata.saveJson.YCYSBM) {
                    //                 this.ycysValue.push(item1.BM);
                    //                 this.ycysValue.push(item2.BM);
                    //             }
                    //         })
                    //     }
                    // }
                    if(item1.BM === this.formdata.saveJson.YCYSFL2){
                        this.ycysValue.push(item1.BM);
                        if(this.formdata.saveJson.YCYSBM){
                            if(item1.CHILDREN && item1.CHILDREN.length > 0) {
                                item1.CHILDREN.forEach((item2,index2)=>{
                                    if(item2.BM === this.formdata.saveJson.YCYSBM) {
                                        this.ycysValue.push(item2.BM);
                                    }
                                })
                            }
                        }
                    }
                })
            }
        },
        // 获取遗产要素列表
        async getYcysList() {
            this.ycysList = [];
            // let result = await this.getYcysdtList();
            let result = await this.GetYczcAndYcys();
            console.log(111,'result: ', result);
            if (result && result.length > 0) {
                this.ycysList = result;
                this.ycysList.map((item)=>{
                    item.BM = item.YCZC;
                    item.MC = item.YCZC;
                })
                this.handleYcysValue();
            }
        },
        handleYcycChanged(val) {
            console.log('handleYcycChanged: ', val);
            if(!val || val.length === 0) {
                this.formdata.saveJson.YCYSFL2 = '';
                this.formdata.saveJson.YCYSBM = '';
            } else if(val.length===1) {
                // 遗产组成
                this.formdata.saveJson.YCYSFL2 = val[val.length - 1];
                this.formdata.saveJson.YCYSBM = '';
            } else if(val.length===2) {
                this.formdata.saveJson.YCYSFL2 = val[val.length - 2];
                this.formdata.saveJson.YCYSBM = val[val.length - 1];
            }
            console.log(222,'val: ', val, 33333, this.formdata.saveJson.YCYSBM);
        },
        // 获取周期单位列表
        async getZqdwList() {
            this.zqdwList = [];
            let result = await this.GetZqdwList();
            if (result && result.length > 0) {
                this.zqdwList = result;
            }
        },
        // 获取监测指标列表
        async getJczbList() {
            this.jczbList = [];
            let result = await this.GetZBXList();
            if (result && result.length > 0) {
                this.jczbList = result;
            }
        },
        async getFromData() {
            let params = {
                // itemid: this.formdata.itemID,
                ID: this.id,
            };
            let result = await this.GetDetailsEquipmentData(params);
            if (result && result.length > 0) {
                Object.assign(this.formdata.saveJson, result[0]);
                // 处理启动与否
                if(this.formdata.saveJson.SFQY===1) {
                    this.onOffList[0].state=true;
                }else{
                    this.onOffList[0].state=false;
                }
                if (
                    this.formdata.saveJson.AZDJD &&
                    this.formdata.saveJson.AZDWD
                ) {
                    this.jwdValue = `${this.formdata.saveJson.AZDJD},${this.formdata.saveJson.AZDWD}`;
                }
                // 处理监测指标
                this.jczbValue = this.formdata.saveJson.JCZB
                    ? this.formdata.saveJson.JCZB.split(",")
                    : [];
                // 处理遗产要素
                // this.handleYcysValue();
                if(this.$route.query.type!=='0'){
                    this.getYcysList();
                }
            }
        },
        // 保存暂不提交未做验证
        async saveNosubmit() {
            let _this = this;
            // this.formdata.saveJson.shzt = 1;

            let basicValidPromise = this.$refs.formdata.validate();
            Promise.all([
                basicValidPromise,
                // eslint-disable-next-line no-unused-vars
            ])
                .then(([basicValid]) => {
                    this.jczbNewArr = [];
                    // 监测指标字段处理
                    // 遍历监测指标下拉框数据源，如果数据源里不存在新输入的绑定值，先调保存下拉框数据源服务，再调保存数据服务
                    _this.handleJczbItem(_this.jczbValue);
                })
                .catch(() => {
                    this.$message({
                        type: "warning",
                        message: "请检查是否填写完整！",
                    });
                });
        },
        // 处理监测指标创建的新条目
        async handleJczbItem(val) {
            let _this = this;
            if (val && val.length > 0) {
                if (_this.jczbList && _this.jczbList.length > 0) {
                    let newOptions = [];
                    _this.jczbList.forEach((item) => {
                        newOptions.push(item.Id);
                        newOptions.push(item.Mc);
                    });
                    val.forEach((ele) => {
                        if (
                            newOptions.indexOf(ele) == -1 &&
                            _this.jczbNewArr.indexOf(ele) == -1
                        ) {
                            _this.jczbNewArr.push(ele);
                        }
                    });
                } else {
                    _this.jczbNewArr = val;
                }
            }

            // _this.jczbNewArr 是剩余的数组
            // 此处调服务，保存数组里的项:
            let jczbNewArr = [];
            let strjsonStr = "";
            let jczbRes = null;
            if (_this.jczbNewArr && _this.jczbNewArr.length > 0) {
                _this.jczbNewArr.forEach((jczbEle) => {
                    let tmpItem = {
                        Mc: jczbEle,
                    };
                    jczbNewArr.push(tmpItem);
                });
                strjsonStr = JSON.stringify(jczbNewArr);
                jczbRes = await _this.SaveZBX({
                    strjson: strjsonStr,
                });
            }
            if ((strjsonStr && jczbRes.IsSuccess) || !strjsonStr) {
                // 此处调获取监测指标列表的服务，遍历替换
                if (strjsonStr && jczbRes.IsSuccess) {
                    _this.jczbList = [];
                    let jczbRes = await this.GetZBXList();
                    if (jczbRes && jczbRes.length > 0) {
                        _this.jczbList = jczbRes;
                    }
                }
                // 监测指标字段处理
                if (_this.jczbValue && _this.jczbValue.length > 0) {
                    for (let i = 0; i < _this.jczbValue.length; i++) {
                        if (_this.jczbList && _this.jczbList.length > 0) {
                            _this.jczbList.forEach((jczbListItem) => {
                                if (_this.jczbValue[i] === jczbListItem.Mc) {
                                    _this.jczbValue[i] = jczbListItem.Id;
                                }
                            });
                        }
                    }
                    _this.formdata.saveJson.JCZB = _this.jczbValue.toString();
                }
                _this.saveData();
            }
        },
        async saveData() {
            this.formdata.saveJson.FILE = this.metaJson.map(
                (ele) => ele.jcsjid
            );
            console.log("保存设备的数据", this.formdata.saveJson);
            let res = await this.SaveEquipmentData({
                data: this.formdata.saveJson,
                // itemid: this.itemID
            });
            if (res.IsSuccess) {
                this.$message({
                    type: "success",
                    message: "保存成功",
                });
                this.returnList();
            }
        },

        // 返回列表
        returnList() {
            this.$router.push({
                name: "equipmanage_grid",
                query: {
                    sbLx: this.sbLx,
                    activeName: this.$route.query.activeName,
                },
            });
        },

        // 经纬度输入框改变
        handleJwdValueChanged(val) {
            console.log(
                "%c 🍍 val: ",
                "font-size:20px;background-color: #2EAFB0;color:#fff;",
                val
            );
            if (val) {
                let jwdArr = val.split(",");
                if (jwdArr && jwdArr.length === 2 && jwdArr[0] && jwdArr[1]) {
                    let lng = jwdArr[0];
                    let lat = jwdArr[1];
                    // if (longrg.test(lng) && latreg.test(lat)) {
                    if (
                        !window.lngTest.test(lng) &&
                        !window.latTest.test(lat)
                    ) {
                        this.formdata.saveJson.AZDJD = lng;
                        this.formdata.saveJson.AZDWD = lat;
                    } else {
                        this.common.showMsg("请填写正确的经纬度", "warning");
                    }
                } else {
                    this.common.showMsg("请填写正确的经纬度", "warning");
                }
            }
        },
        // 点击地图选点按钮
        handleMapPointBtnClick(pointValue, type, index) {
            this.dialogDataMapPoint.dialog = true;
            this.currentData = {
                pointValue: pointValue,
                type: type,
                index: index,
            };
        },
        // 地图选点监听事件
        handleMapPointClick(val) {
            if (val.type === "sb") {
                this.formdata.saveJson.AZDJD = val.pointArr[0];
                this.formdata.saveJson.AZDWD = val.pointArr[1];
                this.jwdValue = `${val.pointArr[0]},${val.pointArr[1]}`;
            }
        },
    },
};
</script>
<style lang="scss" scoped>
.titlediv {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
    padding-bottom: 25px;
}

.leftline {
    width: 180px; // width: calc(50% - 108px);
    height: 0px;
    border-bottom: 1px solid #ccc;
}

.subtitle {
    width: 155px;
    height: 30px;
    background-color: #1862ad;
    border-radius: 15px;
    color: #fff;
    line-height: 30px;
    margin: 0 30px;
    text-align: center;
}
.img-item {
    width: 250px;
    height: 300px;
    position: relative;
    margin-right: 10px;
    &:hover {
        .delete-cover {
            display: block;
            // display: flex;
            background: rgba(#ddd, 0.1);
        }
    }
    .delete-cover {
        position: absolute;
        right: 0;
        top: 0;
        // height: 100%;
        // width: 100%;
        // transform: translate(-50%, -50%);
        display: none;
        // align-items: center;
        // justify-content: center;
        button {
            cursor: pointer;
        }
    }
}
.ycys-cascader {
    width: 100%;
}
</style>
